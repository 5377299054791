<template>
  <v-container fluid class="fluid">
    <h1 class="text-left product-title" v-html="$t('application_users_header')"></h1>

    <v-container>
      <v-row class="row search spacer-md">
        <v-col cols="12" md="4" v-if="applicationUser.id">
          <v-form ref="editApplicationUserForm" @submit.prevent="onSubmit()" id="editApplicationUserForm">
            <v-text-field type="text" id="name" :label="$t('application_users_username')" v-model="applicationUser.name" @keydown.enter.prevent></v-text-field>
            <v-text-field id="email" :label="$t('application_users_agate_email')" v-model="applicationUser.email" @keydown.enter.prevent></v-text-field>
            <v-text-field
              :rules="[$rules.required, $rules.length7]"
              type="number"
              id="agateId"
              :label="$t('application_users_agate_nr')"
              v-model="applicationUser.agateId"
            />
            <v-text-field
              type="number"
              id="agateIntegrationId"
              :label="$t('application_users_agate_integration_id')"
              v-model="applicationUser.agateIntegrationId"
            />
            <v-text-field
              :rules="[$rules.required]"
              id="person_last_name"
              :label="$t('application_users_last_name')"
              v-model="applicationUser.person.lastName"
            />
            <v-text-field id="person_first_name" :label="$t('application_users_first_name')" v-model="applicationUser.person.firstName" />
            <v-text-field
              type="text"
              id="initials"
              :label="$t('person_initials')"
              v-model="applicationUser.person.initials"
              @keydown.enter.prevent
            ></v-text-field>
            <v-text-field id="person_cantonal_id" :label="$t('application_users_cantonal_id')" v-model="applicationUser.person.cantonalId" />
            <v-switch id="application_users_active" v-model="applicationUser.active" color="primary" :label="$t('application_users_active')"></v-switch>

            <v-btn class="m-2" color="primary" type="submit"><span v-html="$t('application_users_update')" /></v-btn>
            <v-btn class="m-2" color="secondary" :to="{ name: 'applicationUsers_read' }"><span v-html="$t('application_users_close')" /></v-btn>
          </v-form>

          <!--        Dokuments/Remarks/History-->
          <v-col class="item card-spacing custom-margin-top">
            <rdhComponent
              :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
              :remarks_show="true"
              :remarks_tag="'application_users_' + $route.params.id"
              :documents_show="true"
              :documents_tag="'application_users_' + $route.params.id"
              :history_show="true"
              :history_url="'/applicationUsers/' + $route.params.id"
              ref="rdh"
            ></rdhComponent>
          </v-col>
        </v-col>

        <!-- Rollen -->
        <v-col cols="12" md="6">
          <h1 v-html="$t('application_user_add_roles_title')"></h1>
          <v-row>
            <v-col>
              <h2 v-html="$t('application_users_add_role')"></h2>
              <div v-if="applicationUserApplicationRoles && applicationUserApplicationRoles.length > 0">
                <!-- existing relations -->
                <v-data-table-server
                  density="compact"
                  :headers="roleFields"
                  :items="applicationUserApplicationRoles"
                  :hide-default-footer="true"
                  disable-pagination
                  :loading="rolesLoading"
                  :itemsLength="applicationUserApplicationRoles.length"
                >
                  <template v-slot:[`item.validFrom`]="{ item }">
                    <dbmDatePicker
                      class="nomessage"
                      v-model="item.validFrom"
                      :required="true"
                      @change="
                        (emittedDate) => {
                          item.validFrom = emittedDate
                          patchApplicationUserApplicationRole(item)
                        }
                      "
                    ></dbmDatePicker>
                  </template>
                  <template v-slot:[`item.validUntil`]="{ item }">
                    <dbmDatePicker
                      class="nomessage"
                      v-model="item.validUntil"
                      :clearable="true"
                      @change="
                        (emittedDate) => {
                          item.validUntil = emittedDate
                          patchApplicationUserApplicationRole(item)
                        }
                      "
                    ></dbmDatePicker>
                  </template>
                </v-data-table-server>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn id="add-role-submit-btn" class="add-relation-btn" color="secondary" @click="addRole()"
                ><span v-html="$t('masterdata_dairies_add')"
              /></v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="top-margin">
                <h2 v-html="$t('application_users_add_legal_entity')"></h2>
                <div v-if="applicationUserPersons && applicationUserPersons.length > 0">
                  <v-data-table-server
                    density="compact"
                    :headers="applicationUserPersonfields"
                    :items="applicationUserPersons"
                    :hide-default-footer="true"
                    disable-pagination
                    :loading="personsLoading"
                    :items-length="applicationUserPersons.length"
                  >
                    <template v-slot:[`item.validFrom`]="{ item }">
                      <dbmDatePicker
                        class="nomessage"
                        v-model="item.validFrom"
                        :required="true"
                        @change="
                          (emittedDate) => {
                            item.validFrom = emittedDate
                            patchApplicationUserPerson(item)
                          }
                        "
                      ></dbmDatePicker>
                    </template>
                    <template v-slot:[`item.validUntil`]="{ item }">
                      <dbmDatePicker
                        class="nomessage"
                        v-model="item.validUntil"
                        :clearable="true"
                        @change="
                          (emittedDate) => {
                            item.validUntil = emittedDate
                            patchApplicationUserPerson(item)
                          }
                        "
                      ></dbmDatePicker>
                    </template>
                    <template v-slot:[`item.deleteApplicationUserPerson`]="{ item }">
                      <v-btn :loading="personsLoading" :id="'delete_' + item.id" @click="deleteApplicationUserPerson(item.id)" icon>
                        <v-icon dbmblueprimary>mdi-minus-circle</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table-server>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn id="add-legal-entity-submit-btn" class="add-relation-btn" color="secondary" @click="addLegalEntity()"
                ><span v-html="$t('masterdata_dairies_add')"
              /></v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" v-if="$getBeta()" class="beta">
              <h2 v-html="$t('masterdata_farms_hierarchy')"></h2>
              <hierarchy type="User" :id="$route.params.id"></hierarchy>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--            Legal Entities Dialog -->
      <v-dialog max-width="1200px" v-model="legalEntitiesDialog" id="applicationUsers-legalEntities-dialog" :loading="personsLoading">
        <v-form @submit.prevent="createApplicationUserApplicationPerson()" ref="legalEntitiesForm">
          <v-card>
            <v-card-title><span v-html="$t('application_users_add_new_legal_entity')" /></v-card-title>
            <v-card-text>
              <!--          search-cards-->
              <v-container>
                <v-row class="row search spacer-md">
                  <v-col cols="6" md="4" xl="4">
                    <search-legal-entity ref="search-legal-entity" @search="search" />
                  </v-col>
                  <v-col cols="6" md="4" xl="4">
                    <search-contact ref="search-contact" @search="search" />
                  </v-col>
                </v-row>
                <v-btn color="primary" @click="search"><span v-html="$t('applicationUsers_search')" /></v-btn>
                <v-btn color="secondary" @click="reset"><span v-html="$t('applicationUsers_reset')" /></v-btn>
                <v-data-table-server
                  :headers="legalEntityFields"
                  :items="legalEntities"
                  :hide-default-footer="true"
                  :loading="legalEntitiesLoading"
                  :itemsLength="legalEntities.length"
                >
                  <template v-slot:[`item.legalEntityUid`]="{ item }">
                    {{ item.legalEntityUid === null ? $t('none') : item.legalEntityUid }}
                  </template>
                  <template v-slot:[`item.placeholder`]="{ item }">
                    <v-btn color="secondary" @click="setLegalEntityId(item)"><span v-html="$t('application_users_select')" /></v-btn>
                  </template>
                </v-data-table-server>
                <v-spacer class="spacer-md"></v-spacer>
                <v-row class="row search spacer-md">
                  <v-col cols="6" md="4" xl="4">
                    <v-text-field
                      readonly
                      id="application_users_legal_entity_uid"
                      v-model="legalEntityUid"
                      :label="$t('application_users_legal_entity_uid')"
                      type="text"
                    />
                  </v-col>
                  <v-col cols="6" md="4" xl="4">
                    <DbmDatePicker v-model="applicationUserPerson.validFrom" :dateLabel="$t('applicationUsers_legalEntities_valid_from')" :required="true" />
                  </v-col>

                  <v-col cols="6" md="4" xl="4">
                    <DbmDatePicker v-model="applicationUserPerson.validUntil" :dateLabel="$t('applicationUsers_legalEntities_valid_until')" :clearable="true" />
                  </v-col>
                </v-row>
              </v-container>
              <v-alert v-model="showLegalEntityErrorMessage" class="wrong-file-type-alert" color="red" type="error" density="compact" dismissible>
                {{ legalEntityErrorMessage }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn id="applicationuser-edit-legalentities-submit-btn" class="m-2" color="primary" type="submit"
                ><span v-html="$t('applicationUsers_legalEntities_add_button')"
              /></v-btn>
              <v-btn id="applicationuser-edit-legalentities-close-btn" class="m-2" color="secondary" @click="closeDialog()"
                ><span v-html="$t('applicationUsers_legalEntities_close')"
              /></v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <!--      Roles Dialog -->
      <v-dialog v-model="rolesDialog" max-width="800px" id="application_users_application_roles_dialog">
        <v-form ref="rolesForm" @submit.prevent="createApplicationUserApplicationRole()">
          <v-card>
            <v-card-title><span v-html="$t('application_users_add_new_role_title')" /></v-card-title>
            <v-card-text>
              <v-container>
                <!--          search-cards-->
                <v-row class="row search spacer-md">
                  <v-col cols="12">
                    <v-select
                      :rules="[(v) => !!v]"
                      :items="applicationRoles"
                      v-model="applicationUserApplicationRole.applicationRole.id"
                      item-value="id"
                      :item-title="this.$getLangKey()"
                      id="applicationRoles"
                      :label="$t('application_users_applicationRoles')"
                      @keydown.enter.prevent
                    ></v-select>
                  </v-col>
                  <v-col cols="6" md="4" xl="4">
                    <DbmDatePicker
                      v-model="applicationUserApplicationRole.validFrom"
                      :dateLabel="$t('applicationUsers_legalEntities_valid_from')"
                      :required="true"
                    />
                  </v-col>
                  <v-col cols="6" md="4" xl="4">
                    <DbmDatePicker
                      v-model="applicationUserApplicationRole.validUntil"
                      :dateLabel="$t('applicationUsers_legalEntities_valid_until')"
                      :clearable="true"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <v-alert v-model="showRolesErrorMessage" class="wrong-file-type-alert" color="red" type="error" density="compact" dismissible>
                {{ rolesErrorMessage }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn type="submit" id="applicationuser-edit-roles-submit-btn" class="m-2" color="primary"
                ><span v-html="$t('applicationUsers_legalEntities_add_button')"
              /></v-btn>
              <v-btn id="applicationuser-edit-roles-close-btn" class="m-2" color="secondary" @click="closeDialog()"
                ><span v-html="$t('applicationUsers_legalEntities_close')"
              /></v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script lang="ts">
import { apiURL, emitter } from '@/main'
import { showError } from '@/services/axios'
import { Term } from '@/services/term'
import searchLegalEntity from '@/components/searchCards/searchLegalEntity.vue'
import { useSearchLegalEntityStore } from '@/store/SearchLegalEntityStore'
import searchContact from '@/components/searchCards/searchContact.vue'
import { useSearchContactStore } from '@/store/SearchContactStore'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import _ from 'lodash'
import rdhComponent from '@/components/rdh/rdhComponent'
import Hierarchy from '@/components/hierarchy.vue'
import { DTSHeaders } from '@/services/BackendService'

const defaultApplicationUserPerson = {
  applicationUser: { id: undefined },
  legalEntity: { id: undefined },
  validFrom: undefined,
  validUntil: undefined
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'applicationUsersEdit',
  components: {
    searchLegalEntity,
    searchContact,
    DbmDatePicker,
    rdhComponent: rdhComponent,
    hierarchy: Hierarchy
  },
  data() {
    return {
      legalEntityUid: undefined,
      legalEntityErrorMessage: undefined,
      showLegalEntityErrorMessage: false,
      rolesErrorMessage: undefined,
      showRolesErrorMessage: false,
      applicationUserPerson: defaultApplicationUserPerson,
      applicationUser: {
        name: undefined,
        agateId: undefined,
        email: undefined,
        active: undefined,
        person: {
          firstName: undefined,
          initials: undefined,
          lastName: undefined,
          cantonalId: undefined
        }
      },
      applicationUserApplicationRole: {
        applicationUser: { id: undefined },
        applicationRole: { id: undefined },
        validFrom: undefined,
        validUntil: undefined
      },
      applicationUserApplicationRoles: [],
      applicationUserPersons: [],
      rolesDialog: false,
      legalEntitiesDialog: false,
      applicationRoles: [],
      persons: [],
      legalEntities: [],
      roleFields: <DTSHeaders>[
        {
          title: this.$t('application_users_name'),
          key: this.$getLangKey(),
          sortable: false,
          width: '50%'
        },
        {
          title: this.$t('application_users_valid_from'),
          key: 'validFrom',
          sortable: false,
          width: '25%'
        },
        {
          title: this.$t('application_users_valid_until'),
          key: 'validUntil',
          sortable: false,
          width: '25%'
        }
      ],
      applicationUserPersonfields: <DTSHeaders>[
        {
          title: this.$t('application_users_person_uid'),
          key: 'legalEntityUid',
          sortable: false,
          width: '10%',
          align: 'left'
        },
        {
          title: this.$t('application_users_person_name'),
          key: 'legalEntityAddressName',
          sortable: false,
          width: '15%'
        },
        {
          title: this.$t('application_users_person_username'),
          key: 'name',
          sortable: false,
          width: '15%'
        },
        {
          title: this.$t('application_users_valid_from'),
          key: 'validFrom',
          sortable: false,
          width: '25%'
        },
        {
          title: this.$t('application_users_valid_until'),
          key: 'validUntil',
          sortable: false,
          width: '25%'
        },
        {
          key: 'deleteApplicationUserPerson',
          sortable: false,
          width: '5%'
        }
      ],
      legalEntityFields: <DTSHeaders>[
        {
          title: this.$t('application_users_legal_entity_uid'),
          key: 'legalEntityUid',
          sortable: false,
          width: '20%',
          align: 'left'
        },
        {
          title: this.$t('application_users_legal_entity_name1'),
          key: 'legalEntityName1',
          sortable: false,
          width: '20%'
        },
        {
          title: this.$t('application_users_legal_entity_name2'),
          key: 'legalEntityName2',
          sortable: false,
          width: '25%'
        },
        {
          title: this.$t('application_users_valid_until'),
          key: 'info',
          sortable: false,
          width: '25%'
        },
        {
          key: 'placeholder',
          sortable: false,
          width: '10%'
        }
      ],
      loading: false,
      rolesLoading: false,
      personsLoading: false,
      legalEntitiesLoading: false
    }
  },
  computed: {
    term(): any {
      return [
        ...Term.buildTermItems(useSearchLegalEntityStore(), useSearchLegalEntityStore().items),
        ...Term.buildTermItems(useSearchContactStore(), useSearchContactStore().items)
      ]
    }
  },
  methods: {
    personName: (person) => person.firstName + ' ' + person.lastName,
    setLegalEntityId(item) {
      this.applicationUserPerson.legalEntity.id = item.legalEntityId
      this.applicationUserPerson.name = item.legalEntityName1
      this.legalEntityUid = item.legalEntityUid
    },
    reset() {
      this.$refs['search-contact'].reset()
      this.$refs['search-legal-entity'].reset()
    },
    // Update Benutzer
    async onSubmit() {
      try {
        this.loading = true
        if (!(await this.$refs.editApplicationUserForm.validate()).valid) return

        if (this.applicationUser.person.initials === '' || this.applicationUser.person.initials === undefined) {
          const firstInitial = this.applicationUser.person.firstName ? this.applicationUser.person.firstName[0] : ''
          const lastInitial = this.applicationUser.person.lastName ? this.applicationUser.person.lastName[0] : ''
          this.applicationUser.person.initials = `${firstInitial}${lastInitial}`
        }
        const result = await this.axios.patch(
          apiURL + '/applicationUsers/' + this.$route.params.id,
          {
            ..._.omit(this.applicationUser, ['allowedLegalEntities', 'allowedRoles', 'lastLogin'])
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        emitter.emit('toastSuccess', this.$t('masterdata_legal_entity_edit_toast_success'))
        emitter.emit('loadHistory')
      } catch (e) {
        showError(e)
      } finally {
        this.loading = false
      }
    },
    addRole() {
      this.rolesDialog = true
      this.loading = false
    },
    addLegalEntity() {
      this.legalEntitiesDialog = true
      this.loading = false
    },
    closeDialog() {
      this.rolesDialog = false

      this.applicationUserPerson = defaultApplicationUserPerson
      this.applicationUserPerson.name = undefined
      this.applicationUserPerson.validFrom = undefined
      this.applicationUserPerson.validUntil = undefined
      this.legalEntityUid = undefined

      this.legalEntitiesDialog = false
    },
    async getApplicationRoles() {
      try {
        this.rolesLoading = true
        const result = await this.axios.get(apiURL + '/applicationRoles', {})
        this.applicationRoles = await result.data
      } catch (e) {
        showError(e)
      } finally {
        this.rolesLoading = false
      }
    },
    // Rolle
    async getApplicationUserApplicationRoles() {
      try {
        this.rolesLoading = true
        const result = await this.axios.get(apiURL + '/applicationUsers/' + this.$route.params.id + '/applicationUserApplicationRoles')
        this.applicationUserApplicationRoles = await result.data
      } catch (e) {
        showError(e)
      } finally {
        this.rolesLoading = false
      }
    },
    // Rechtliche Person
    async getApplicationUserPersons() {
      try {
        this.personsLoading = true
        const result = await this.axios.get(apiURL + '/applicationUsers/' + this.$route.params.id + '/applicationUserLegalEntities')
        this.applicationUserPersons = result.data
      } catch (e) {
        showError(e)
      } finally {
        this.personsLoading = false
      }
    },
    async getPersons() {
      const result = await this.axios.get(apiURL + '/persons/employeesTSM', {})
      this.persons = await result.data
    },
    async createApplicationUserApplicationRole() {
      if ((await this.$refs.rolesForm.validate()).valid) {
        this.applicationUserApplicationRole.applicationUser = {
          id: this.$route.params.id
        }
        try {
          const result = await this.axios.post(
            apiURL + '/applicationUsers/' + this.$route.params.id + '/applicationUserApplicationRoles',
            { ...this.applicationUserApplicationRole },
            { headers: { 'Content-Type': 'application/json' } }
          )

          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
          this.getApplicationUserApplicationRoles()
        } catch (e) {
          this.showRolesErrorMessage = true
          this.rolesErrorMessage = showError(e, 'inline')
        }
      }
    },
    async createApplicationUserApplicationPerson() {
      if ((await this.$refs.legalEntitiesForm.validate()).valid) {
        this.applicationUserPerson.applicationUser = {
          id: this.$route.params.id
        }
        try {
          const result = await this.axios.post(
            apiURL + '/applicationUsers/' + this.$route.params.id + '/applicationUserLegalEntities',
            { ...this.applicationUserPerson },
            { headers: { 'Content-Type': 'application/json' } }
          )
          if (await result) {
            emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
            this.getApplicationUserPersons()
          }
        } catch (e) {
          this.showLegalEntityErrorMessage = true
          this.legalEntityErrorMessage = showError(e, 'inline')
        } finally {
          this.personsLoading = false
        }
      }
    },
    async patchApplicationUserApplicationRole(item) {
      const applicationUserRole = {
        validFrom: item.validFrom,
        validUntil: item.validUntil
      }
      try {
        const result = await this.axios.patch(
          apiURL + '/applicationUsers/' + this.$route.params.id + '/applicationUserApplicationRoles/' + item.id,
          { ...applicationUserRole },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.getApplicationUserApplicationRoles()
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      }
    },
    async patchApplicationUserPerson(item) {
      const applicationUserLegalEntity = {
        validFrom: item.validFrom,
        validUntil: item.validUntil
      }
      try {
        const result = await this.axios.patch(
          apiURL + '/applicationUsers/' + this.$route.params.id + '/applicationUserLegalEntities/' + item.id,
          { ...applicationUserLegalEntity },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.getApplicationUserPersons()
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      }
    },
    async deleteApplicationUserPerson(id) {
      try {
        const result = await this.axios.delete(apiURL + '/applicationUsers/' + this.$route.params.id + '/applicationUserLegalEntities/' + id, {
          headers: { 'Content-Type': 'application/json' }
        })
        if (await result) {
          this.getApplicationUserPersons()
          emitter.emit('toastSuccess', this.$t('toast_save_success_message'))
        }
      } catch (e) {
        showError(e)
      }
    },
    // Benutzer
    async loadApplicationUser(applicationUserId) {
      try {
        const result = await this.axios.get(apiURL + '/applicationUsers/' + applicationUserId, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.applicationUser = result.data
          if (this.applicationUser.person === null) {
            this.applicationUser.person = {
              firstName: undefined,
              lastName: undefined,
              cantonalId: undefined
            }
          }
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async search() {
      this.legalEntitiesLoading = true
      try {
        const response = await this.axios.get(apiURL + '/masterdata/searchLegalEntity', {
          params: {
            ..._.pick(this.$route.query, ['sort', 'size', 'page']),
            term: Term.stringify(this.term)
          }
        })
        this.legalEntities = response.data.content
        if (response.data.totalPages < this.$route.query.page) {
          this.page_n_sort.page = 0
        }
      } catch (e) {
        this.legalEntities = []
        this.legalEntitiesLoading = false
        showError(e)
      } finally {
        this.legalEntitiesLoading = false
      }
    }
  },
  watch: {},
  mounted() {
    this.loadApplicationUser(this.$route.params.id)
    this.getApplicationRoles()
    this.getApplicationUserApplicationRoles()
    this.getApplicationUserPersons()
    this.getPersons()
  }
})
</script>

<style lang="scss" scoped>
.v-data-table-server {
  margin-top: 20px;
}

.custom-margin-top {
  margin-top: 40px;
}
</style>
